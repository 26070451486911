<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        height="50"
      >
        <v-toolbar-title>Equipment Card Test</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-col cols="2">
          <v-btn
            icon
            right
            @click="setFilter"
          >
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>

        <v-btn
          color="accent"
          small
          @click="openDocumentsDialog()"
          class="mr-10"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>Sync From SAP
        </v-btn>

        <v-btn
          color="accent"
          small
          to="/equipment-card/create"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <!-- start of defaults list -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="serviceCalls"
            >
              <template v-slot:item.id="{ item }">
                <v-btn
                  color="accent"
                  text
                  :to="`/equipment-card/edit/${item.id}`"
                >{{ item.id }}</v-btn>
              </template>

              <template v-slot:item.syncStatus="{ item }">
                <v-btn
                  @click="openerrorDocDialog(item)"
                  color="red"
                  icon
                  v-show="item.withErrors == true"
                >
                  <v-icon>mdi-alert</v-icon>
                </v-btn>
                <v-btn
                  color="green"
                  icon
                  v-show="item.withErrors == false"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>

                <v-btn
                  color="grey"
                  icon
                  v-show="item.withErrors == 'upload'"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template>

              <!-- start of posting date template -->
              <template v-slot:item.created_at="{ item }">{{ item.created_at | moment("Do MMM YYYY")}}</template>
              <!-- end of posting date template -->

            </v-data-table>
          </v-col>
        </v-row>

        <!-- end of defaults table list -->
      </v-card-text>
    </v-card>

    <!-- category quick add dialog -->
    <v-dialog
      v-model="errorDocDialog"
      max-width="650px"
    >
      <v-card>
        <v-toolbar
          dense
          color="red"
          dark
        >
          <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.native="errorDocDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                This document was not sync to SAP due to below following error:

              </v-col>
              <v-col cols="12">

                <span>
                  {{this.ErrorMessage}}
                </span>

              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn
                    color="green"
                    dark
                    @click="errorDocDialog = false"
                  >Close</v-btn>
                </v-col>
              </v-row>
            </v-row>

          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->

    <!-- start of  Manufacture Serial modal -->
    <v-dialog
      v-model="extDocDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="extDocDialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>
            Sync From SAP Equipment Cards
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="extDocDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-card-text>
            <v-row dense>
              <v-row>

                <v-col cols="4">
                  <v-autocomplete
                    outlined
                    dense
                    v-model="recordData.ExtFieldName"
                    :items="openDocumentsFields"
                    item-text="Label"
                    item-value="FieldName"
                    label="Select Field"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    outlined
                    dense
                    v-model="recordData.ExtRefDocNum"
                    label="Value"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-btn
                    color="accent"
                    @click="saveOpenDocuments"
                  >
                    <v-icon left>mdi-content-save</v-icon>Save
                  </v-btn>
                </v-col>

              </v-row>
            </v-row>

            <v-divider></v-divider>
            <v-row dense>
              <v-col cols="12">

                <v-data-table
                  loading-text="Loading... Please wait"
                  :headers="openDocumentsHeaders"
                  :items="openDocuments"
                  hide-default-footer
                >

                </v-data-table>
              </v-col>
            </v-row>

          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- end of Manufacture Serial modal-->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    search: "",
    record: {},
    recordData: {},
    ErrorMessage: null,
    serviceCalls: [],
    openDocuments: [],
    openDocumentsFields: [],
    errorDocDialog: false,
    extDocDialog: false,
    headers: [
      { text: "", value: "syncStatus" },
      { text: "Doc No.", value: "id" },
      { text: "SAP#", value: "ExtRef" },
      { text: "CardCode", value: "customer" },
      { text: "Customer Name", value: "custmrName" },
      { text: "Created On", value: "created_at" },
    ],

    openDocumentsHeaders: [
      { text: "Document", value: "objecttype.DocumentName" },
      { text: "Equipment Card No", value: "ExtRef" },
      { text: "Serial No.", value: "ExtRefDocNum" },
      { text: "Synced", value: "status" },
    ],
  }),
  methods: {
    setFilter() {
      this.getData();
    },
    openerrorDocDialog(item) {
      this.ErrorMessage = item.ErrorMessage;
      this.errorDocDialog = true;
    },
    getData() {
      const self = this;
      this.$store
        .dispatch("get", `/equipment-cards`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.serviceCalls = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    openDocumentsDialog() {
      this.extDocDialog = true;
    },

    getOpenDocuments() {
      const self = this;
      this.$store
        .dispatch("get", `/open-documents/176`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.openDocuments = res.ResponseData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getOpenDocumentsFields() {
      const self = this;
      this.$store
        .dispatch("get", `/open-documents-fields/176`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.openDocumentsFields = res.ResponseData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    saveOpenDocuments() {
      const self = this;
      const data = this.recordData;
      data.ObjType = 176;

      const url = "/open-documents";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.extDocDialog = true;
            this.getOpenDocuments();
            this.recordData = {};
            this.$store.commit("loader", false);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loader", false);
        });
    },
  },
  created() {
    this.getOpenDocuments();
    this.getOpenDocumentsFields();
    this.getData();
  },
};
</script>